@font-face {
  font-family: 'synaptic_icon';
  src:
    url('fonts/synaptic_icon.ttf?vfvhyg') format('truetype'),
    url('fonts/synaptic_icon.woff?vfvhyg') format('woff'),
    url('fonts/synaptic_icon.svg?vfvhyg#synaptic_icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-v2-"], [class*=" icon-v2-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'synaptic_icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-v2-Consumer-edge_v3:before {
  content: "\ea62";
}
.icon-v2-Revelio_v3:before {
  content: "\ea63";
}
.icon-v2-Defi-Llama_v3:before {
  content: "\ea64";
}
.icon-v2-searchwithstar:before {
  content: "\ea65";
}
.icon-v2-diamond:before {
  content: "\ea3f";
}
.icon-v2-datasourcescapterra:before {
  content: "\ea45";
}
.icon-v2-datasourceschainalysis:before {
  content: "\ea55";
}
.icon-v2-datasourcessecondmeasure:before {
  content: "\ea5b";
}
.icon-v2-datasourcesquestmobile:before {
  content: "\ea5c";
}
.icon-v2-datasourcesdefillama:before {
  content: "\ea5d";
}
.icon-v2-brandsspectre1:before {
  content: "\ea5e";
}
.icon-v2-datasourcesg2crowd:before {
  content: "\ea5f";
}
.icon-v2-datasourcesconsumer-edge1:before {
  content: "\ea60";
}
.icon-v2-datasourcesrevelio:before {
  content: "\ea61";
}
.icon-v2-box-iso_v3:before {
  content: "\ea3a";
}
.icon-v2-calendar_v3:before {
  content: "\ea3b";
}
.icon-v2-candlestick-chart_v3:before {
  content: "\ea3c";
}
.icon-v2-Chainanalysis_v3:before {
  content: "\ea3d";
}
.icon-v2-clock_v3:before {
  content: "\ea3e";
}
.icon-v2-cpu_v3:before {
  content: "\ea40";
}
.icon-v2-credit-card_v3:before {
  content: "\ea41";
}
.icon-v2-crunchbase_v3:before {
  content: "\ea42";
}
.icon-v2-database_v3:before {
  content: "\ea43";
}
.icon-v2-dealroom_v3:before {
  content: "\ea44";
}
.icon-v2-Earnest_v3:before {
  content: "\ea46";
}
.icon-v2-eye_v3:before {
  content: "\ea47";
}
.icon-v2-github_v3:before {
  content: "\ea48";
}
.icon-v2-Glassdoor_v3:before {
  content: "\ea49";
}
.icon-v2-google_v3-1:before {
  content: "\ea4c";
}
.icon-v2-google_v3:before {
  content: "\ea4d";
}
.icon-v2-HG-Insights_v3:before {
  content: "\ea4e";
}
.icon-v2-Job-opening_v3:before {
  content: "\ea4f";
}
.icon-v2-launcher-icons_v3:before {
  content: "\ea50";
}
.icon-v2-lock_v3:before {
  content: "\ea51";
}
.icon-v2-microphone_v3:before {
  content: "\ea52";
}
.icon-v2-page-edit_v3:before {
  content: "\ea53";
}
.icon-v2-Pitchbook_v3:before {
  content: "\ea54";
}
.icon-v2-Semrush_v3:before {
  content: "\ea56";
}
.icon-v2-smartphone-device_v3:before {
  content: "\ea57";
}
.icon-v2-Stackoverflow_v3:before {
  content: "\ea58";
}
.icon-v2-suitcase_v3:before {
  content: "\ea59";
}
.icon-v2-warning-circle_v3:before {
  content: "\ea5a";
}
.icon-v2-enlarge:before {
  content: "\ea4b";
}
.icon-v2-xtwittercom:before {
  content: "\ea4a";
}
.icon-v2-numberinglined_v3:before {
  content: "\ea34";
  color: #667085;
}
.icon-v2-bulletedlist_v3:before {
  content: "\ea35";
  color: #667085;
}
.icon-v2-calendarlined_v3:before {
  content: "\ea36";
  color: #667085;
}
.icon-v2-numberhash_v3:before {
  content: "\ea37";
  color: #667085;
}
.icon-v2-fontlined_v3:before {
  content: "\ea38";
  color: #667085;
}
.icon-v2-singleselect_v3:before {
  content: "\ea39";
  color: #667085;
}
.icon-v2-noteslined:before {
  content: "\e95c";
}
.icon-v2-numberhash:before {
  content: "\e99b";
}
.icon-v2-sorthightolowlined:before {
  content: "\e99c";
}
.icon-v2-sortlowtohighlined:before {
  content: "\e9fd";
}
.icon-v2-text:before {
  content: "\ea00";
}
.icon-v2-fullscreenlined:before {
  content: "\ea33";
}
.icon-v2-bulletedlist:before {
  content: "\ea2f";
}
.icon-v2-longtext:before {
  content: "\ea30";
}
.icon-v2-rankedlist:before {
  content: "\ea31";
}
.icon-v2-singleselect:before {
  content: "\ea32";
}
.icon-v2-columns:before {
  content: "\ea2e";
}
.icon-v2-checkfilledsmall:before {
  content: "\ea2d";
}
.icon-v2-refresh:before {
  content: "\ea2c";
}
.icon-v2-deltalinedbig:before {
  content: "\ea2a";
}
.icon-v2-percentagelinedbig:before {
  content: "\ea2b";
}
.icon-v2-Targetlined:before {
  content: "\ea28";
}
.icon-v2-Arrowshorizontalreplace:before {
  content: "\ea29";
}
.icon-v2-deltalined:before {
  content: "\ea21";
}
.icon-v2-Table:before {
  content: "\ea22";
}
.icon-v2-Tagged-preview:before {
  content: "\ea23";
}
.icon-v2-Columns:before {
  content: "\ea24";
}
.icon-v2-Rows:before {
  content: "\ea25";
}
.icon-v2-Collapse:before {
  content: "\ea26";
}
.icon-v2-Expand:before {
  content: "\ea27";
}
.icon-v2-redolined:before {
  content: "\ea1d";
}
.icon-v2-undolined:before {
  content: "\ea1e";
}
.icon-v2-arrow-double-up-lined-1:before {
  content: "\ea1f";
}
.icon-v2-arrow-double-up-lined:before {
  content: "\ea20";
}
.icon-v2-crmattio:before {
  content: "\ea1c";
}
.icon-v2-formula:before {
  content: "\e9ff";
}
.icon-v2-Mergecells:before {
  content: "\ea19";
}
.icon-v2-Fittopage:before {
  content: "\ea1a";
}
.icon-v2-Fittowidth:before {
  content: "\ea1b";
}
.icon-v2-dollarlined1:before {
  content: "\ea18";
}
.icon-v2-infofilled:before {
  content: "\e994";
}
.icon-v2-infolined:before {
  content: "\e9b6";
}
.icon-v2-dots-grid:before {
  content: "\ea15";
}
.icon-v2-callfilled:before {
  content: "\ea14";
}
.icon-v2-editstyle2:before {
  content: "\ea16";
}
.icon-v2-envelopefilled:before {
  content: "\ea17";
}
.icon-v2-briefcase:before {
  content: "\ea0e";
}
.icon-v2-buildingfilled:before {
  content: "\ea0f";
}
.icon-v2-graduationhat:before {
  content: "\ea10";
}
.icon-v2-mapfilledtype2:before {
  content: "\ea11";
}
.icon-v2-sectorfilledtype2:before {
  content: "\ea12";
}
.icon-v2-stagefilled:before {
  content: "\ea13";
}
.icon-v2-insightsfilled:before {
  content: "\ea0c";
}
.icon-v2-datasourcegrid:before {
  content: "\ea0d";
}
.icon-v2-buildinglined:before {
  content: "\e95b";
}
.icon-v2-circlehalffilled:before {
  content: "\e95d";
}
.icon-v2-clipboardchecklined:before {
  content: "\ea03";
}
.icon-v2-customiselined:before {
  content: "\ea04";
}
.icon-v2-filelined:before {
  content: "\ea05";
}
.icon-v2-googlecoloured .path1:before {
  content: "\ea06";
  color: rgb(66, 133, 244);
}
.icon-v2-googlecoloured .path2:before {
  content: "\ea07";
  margin-left: -0.9599609375em;
  color: rgb(52, 168, 83);
}
.icon-v2-googlecoloured .path3:before {
  content: "\ea08";
  margin-left: -0.9599609375em;
  color: rgb(251, 188, 5);
}
.icon-v2-googlecoloured .path4:before {
  content: "\ea09";
  margin-left: -0.9599609375em;
  color: rgb(234, 67, 53);
}
.icon-v2-networklined:before {
  content: "\ea0a";
}
.icon-v2-shieldrighthalffilled:before {
  content: "\ea0b";
}
.icon-v2-processorlined:before {
  content: "\ea02";
}
.icon-v2-Crownfilled:before {
  content: "\ea01";
  color: #667085;
}
.icon-v2-overviewlined:before {
  content: "\e9f7";
}
.icon-v2-clipboarddollarlined:before {
  content: "\e9f8";
}
.icon-v2-datasourcesnew:before {
  content: "\e9f9";
}
.icon-v2-hourglasslined:before {
  content: "\e9fa";
}
.icon-v2-gridsparklelined:before {
  content: "\e9fb";
}
.icon-v2-datelined:before {
  content: "\e9fc";
}
.icon-v2-percentage:before {
  content: "\e9fe";
}
.icon-v2-checkverified:before {
  content: "\e9f5";
}
.icon-v2-diamondfilled:before {
  content: "\e9f6";
}
.icon-v2-similar:before {
  content: "\e9f4";
}
.icon-v2-flamefilled:before {
  content: "\e9f3";
}
.icon-v2-doctextfilled:before {
  content: "\e9ef";
}
.icon-v2-glassdoorfilled:before {
  content: "\e9f0";
}
.icon-v2-gridsparkle1:before {
  content: "\e9f1";
}
.icon-v2-sparklesfilled:before {
  content: "\e9f2";
}
.icon-v2-clipboarddollar:before {
  content: "\e9ed";
}
.icon-v2-medal:before {
  content: "\e9ee";
}
.icon-v2-dollarnote:before {
  content: "\e9ec";
}
.icon-v2-lightning:before {
  content: "\e9eb";
}
.icon-v2-hourglassfilled:before {
  content: "\e9ea";
}
.icon-v2-layersfilled:before {
  content: "\e9e9";
}
.icon-v2-calendarfilled:before {
  content: "\e9e7";
}
.icon-v2-peoplefilled:before {
  content: "\e9e8";
}
.icon-v2-circlelined:before {
  content: "\e9da";
}
.icon-v2-IndexIO:before {
  content: "\e9e6";
}
.icon-v2-exclamationtrianglefilled1:before {
  content: "\e9e4";
}
.icon-v2-synapticlistfilled:before {
  content: "\e9e5";
}
.icon-v2-screenerfilled:before {
  content: "\e934";
}
.icon-v2-thumbs-uproundedlined:before {
  content: "\e964";
}
.icon-v2-worldwideweblined:before {
  content: "\e9d6";
}
.icon-v2-loudspeakerroundedlined:before {
  content: "\e9d7";
}
.icon-v2-gemlined:before {
  content: "\e9d8";
}
.icon-v2-datacubefilled:before {
  content: "\e9d9";
}
.icon-v2-eyeopenline:before {
  content: "\e9db";
}
.icon-v2-layerslined:before {
  content: "\e9dc";
}
.icon-v2-globelined:before {
  content: "\e9dd";
}
.icon-v2-sharelined:before {
  content: "\e9de";
}
.icon-v2-eyecloseline:before {
  content: "\e9df";
}
.icon-v2-briefcaselined:before {
  content: "\e9e0";
}
.icon-v2-checkfilled:before {
  content: "\e9e1";
}
.icon-v2-exclamationfilled:before {
  content: "\e9e2";
}
.icon-v2-crossfilled:before {
  content: "\e9e3";
}
.icon-v2-puzzlelined:before {
  content: "\e900";
}
.icon-v2-currency-rupeelined:before {
  content: "\e901";
}
.icon-v2-shieldlined:before {
  content: "\e902";
}
.icon-v2-homelined:before {
  content: "\e903";
}
.icon-v2-buildingtriplelinedlined:before {
  content: "\e904";
}
.icon-v2-targetlined:before {
  content: "\e905";
}
.icon-v2-phonelined:before {
  content: "\e906";
}
.icon-v2-face-idlined:before {
  content: "\e907";
}
.icon-v2-alarmclocklined:before {
  content: "\e908";
}
.icon-v2-thumbs-downroundedlined:before {
  content: "\e909";
}
.icon-v2-userslined:before {
  content: "\e90a";
}
.icon-v2-serverlined:before {
  content: "\e90b";
}
.icon-v2-gitbranchlined:before {
  content: "\e90c";
}
.icon-v2-giftroundedlined:before {
  content: "\e90d";
}
.icon-v2-currency-eurolined:before {
  content: "\e90e";
}
.icon-v2-currency-bitcoinlined:before {
  content: "\e90f";
}
.icon-v2-editlined:before {
  content: "\e910";
}
.icon-v2-archivelined:before {
  content: "\e911";
}
.icon-v2-mapslined:before {
  content: "\e912";
}
.icon-v2-inboxangularlined:before {
  content: "\e913";
}
.icon-v2-atomlined:before {
  content: "\e914";
}
.icon-v2-flashlined:before {
  content: "\e915";
}
.icon-v2-loudspeakerlined:before {
  content: "\e916";
}
.icon-v2-gridlined:before {
  content: "\e917";
}
.icon-v2-currency-poundlined:before {
  content: "\e918";
}
.icon-v2-cryptocurrencylined:before {
  content: "\e919";
}
.icon-v2-scannerlined:before {
  content: "\e91a";
}
.icon-v2-heart-linedlined:before {
  content: "\e91b";
}
.icon-v2-buildingsinglelined:before {
  content: "\e91c";
}
.icon-v2-inboxrectanglelined:before {
  content: "\e91d";
}
.icon-v2-fingerprintlined:before {
  content: "\e91e";
}
.icon-v2-imagelined:before {
  content: "\e91f";
}
.icon-v2-edituserlined:before {
  content: "\e920";
}
.icon-v2-walletlined:before {
  content: "\e921";
}
.icon-v2-passcodelined:before {
  content: "\e922";
}
.icon-v2-keylined:before {
  content: "\e923";
}
.icon-v2-trenduplined:before {
  content: "\e924";
}
.icon-v2-trenddownlined:before {
  content: "\e925";
}
.icon-v2-pie-chartlined:before {
  content: "\e926";
}
.icon-v2-bar-charthorizontallined:before {
  content: "\e927";
}
.icon-v2-homeclosedlined:before {
  content: "\e928";
}
.icon-v2-linkexternallined:before {
  content: "\e929";
}
.icon-v2-uploadcloudlined:before {
  content: "\e92a";
}
.icon-v2-uploadcirclelined:before {
  content: "\e92b";
}
.icon-v2-tagretarrowlined:before {
  content: "\e92c";
}
.icon-v2-rocketlined:before {
  content: "\e92d";
}
.icon-v2-chiplined:before {
  content: "\e92e";
}
.icon-v2-bookclosedlined:before {
  content: "\e92f";
}
.icon-v2-cursorlined:before {
  content: "\e930";
}
.icon-v2-hourglassemptylined:before {
  content: "\e931";
}
.icon-v2-clock2lined:before {
  content: "\e932";
}
.icon-v2-calendarpluslined:before {
  content: "\e933";
}
.icon-v2-userpluslined:before {
  content: "\e935";
}
.icon-v2-user-minuslined:before {
  content: "\e936";
}
.icon-v2-users-checklined:before {
  content: "\e937";
}
.icon-v2-crmaffinity:before {
  content: "\e938";
}
.icon-v2-brandscopper:before {
  content: "\e939";
}
.icon-v2-datasourcesconsumer-edge:before {
  content: "\e93a";
}
.icon-v2-brandsg2crowd:before {
  content: "\e93b";
}
.icon-v2-brandsspectre:before {
  content: "\e93c";
}
.icon-v2-crmsalesforce:before {
  content: "\e93d";
}
.icon-v2-datasourceshg-insights:before {
  content: "\e93e";
}
.icon-v2-datasourcesearnest:before {
  content: "\e93f";
}
.icon-v2-datasourcesgithub:before {
  content: "\e940";
}
.icon-v2-brandstwitter:before {
  content: "\e941";
}
.icon-v2-synapticmono:before {
  content: "\e942";
}
.icon-v2-brandslinkedin:before {
  content: "\e943";
}
.icon-v2-brandsyoutube:before {
  content: "\e944";
}
.icon-v2-brandsfacebook:before {
  content: "\e945";
}
.icon-v2-iOSmono:before {
  content: "\e946";
}
.icon-v2-socialappstoremono:before {
  content: "\e947";
}
.icon-v2-androidmono:before {
  content: "\e948";
}
.icon-v2-socialplaystoremono:before {
  content: "\e949";
}
.icon-v2-socialappstore:before {
  content: "\e94a";
}
.icon-v2-synapticbrandcolour:before {
  content: "\e94b";
}
.icon-v2-datacubelined:before {
  content: "\e94c";
}
.icon-v2-clipboard:before {
  content: "\e94d";
}
.icon-v2-dollarlined:before {
  content: "\e94e";
}
.icon-v2-stackchart100lined:before {
  content: "\e94f";
}
.icon-v2-stackchartlined:before {
  content: "\e950";
}
.icon-v2-linechartlined:before {
  content: "\e951";
}
.icon-v2-stackoverflowlined:before {
  content: "\e952";
}
.icon-v2-slacklined:before {
  content: "\e953";
}
.icon-v2-crossoverlined:before {
  content: "\e954";
}
.icon-v2-pulselined:before {
  content: "\e955";
}
.icon-v2-barchartlined:before {
  content: "\e956";
}
.icon-v2-outliernegativelined:before {
  content: "\e957";
}
.icon-v2-outlierpositivelined:before {
  content: "\e958";
}
.icon-v2-sendlined:before {
  content: "\e959";
}
.icon-v2-creditcardlined:before {
  content: "\e95a";
}
.icon-v2-userlined:before {
  content: "\e95e";
}
.icon-v2-synapticlistlined:before {
  content: "\e95f";
}
.icon-v2-stagerredlistlined:before {
  content: "\e960";
}
.icon-v2-customiselined1:before {
  content: "\e961";
}
.icon-v2-datasourceslined:before {
  content: "\e962";
}
.icon-v2-screenerlined:before {
  content: "\e963";
}
.icon-v2-moneycirclelined:before {
  content: "\e965";
}
.icon-v2-moneycirclefilled:before {
  content: "\e966";
}
.icon-v2-moneysqlined:before {
  content: "\e967";
}
.icon-v2-moneysqfilled:before {
  content: "\e968";
}
.icon-v2-trophyfilled:before {
  content: "\e969";
}
.icon-v2-bankfiiled:before {
  content: "\e96a";
}
.icon-v2-sector-filled:before {
  content: "\e96b";
}
.icon-v2-stagelined:before {
  content: "\e96c";
}
.icon-v2-chatdottedlined:before {
  content: "\e96d";
}
.icon-v2-investor2lined:before {
  content: "\e96e";
}
.icon-v2-entrylined:before {
  content: "\e96f";
}
.icon-v2-exitlined:before {
  content: "\e970";
}
.icon-v2-uploadlined:before {
  content: "\e971";
}
.icon-v2-downloadlined:before {
  content: "\e972";
}
.icon-v2-minuscirclelined:before {
  content: "\e973";
}
.icon-v2-pluscirclelined:before {
  content: "\e974";
}
.icon-v2-pluslined:before {
  content: "\e975";
}
.icon-v2-minuslined:before {
  content: "\e976";
}
.icon-v2-crosslined-1:before {
  content: "\e977";
}
.icon-v2-checklined:before {
  content: "\e978";
}
.icon-v2-cameralined:before {
  content: "\e979";
}
.icon-v2-mobilelined:before {
  content: "\e97a";
}
.icon-v2-desktoplined:before {
  content: "\e97b";
}
.icon-v2-hamburgerlined:before {
  content: "\e97c";
}
.icon-v2-attachmentlined:before {
  content: "\e97d";
}
.icon-v2-numberinglined:before {
  content: "\e97e";
}
.icon-v2-centrealignmentlined:before {
  content: "\e97f";
}
.icon-v2-rightalignmentlined:before {
  content: "\e980";
}
.icon-v2-leftalignmentlined:before {
  content: "\e981";
}
.icon-v2-fontlined:before {
  content: "\e982";
}
.icon-v2-underlinelined:before {
  content: "\e983";
}
.icon-v2-italiclined:before {
  content: "\e984";
}
.icon-v2-boldlined:before {
  content: "\e985";
}
.icon-v2-unlinklined:before {
  content: "\e986";
}
.icon-v2-linklined:before {
  content: "\e987";
}
.icon-v2-bellslashlined:before {
  content: "\e988";
}
.icon-v2-unlocklined:before {
  content: "\e989";
}
.icon-v2-lockclosedlined:before {
  content: "\e98a";
}
.icon-v2-bulblined:before {
  content: "\e98b";
}
.icon-v2-crosslined:before {
  content: "\e98c";
}
.icon-v2-funnellined:before {
  content: "\e98d";
}
.icon-v2-editlined1:before {
  content: "\e98e";
}
.icon-v2-pinlined:before {
  content: "\e98f";
}
.icon-v2-folderlined:before {
  content: "\e990";
}
.icon-v2-pinnodividerlined:before {
  content: "\e991";
}
.icon-v2-exclamationcirclelined:before {
  content: "\e992";
}
.icon-v2-copylined:before {
  content: "\e993";
}
.icon-v2-thumbsupfilled:before {
  content: "\e995";
}
.icon-v2-thumbsuplined:before {
  content: "\e996";
}
.icon-v2-spinnerlined:before {
  content: "\e997";
}
.icon-v2-giftlined:before {
  content: "\e998";
}
.icon-v2-blocklined:before {
  content: "\e999";
}
.icon-v2-savelined:before {
  content: "\e99a";
}
.icon-v2-dotlined:before {
  content: "\e99d";
}
.icon-v2-binlined:before {
  content: "\e99e";
}
.icon-v2-clocklined:before {
  content: "\e99f";
}
.icon-v2-calendardottedlined:before {
  content: "\e9a0";
}
.icon-v2-calendarlined:before {
  content: "\e9a1";
}
.icon-v2-searchlined:before {
  content: "\e9a2";
}
.icon-v2-resetlined:before {
  content: "\e9a3";
}
.icon-v2-sortlined:before {
  content: "\e9a4";
}
.icon-v2-logoutlined:before {
  content: "\e9a5";
}
.icon-v2-ellipsis-v:before {
  content: "\e9a6";
}
.icon-v2-ellipsis-h:before {
  content: "\e9a7";
}
.icon-v2-bulbfilled:before {
  content: "\e9a8";
}
.icon-v2-crosscirclefilled:before {
  content: "\e9a9";
}
.icon-v2-editfilled:before {
  content: "\e9aa";
}
.icon-v2-funnelfilled:before {
  content: "\e9ab";
}
.icon-v2-pinfilled:before {
  content: "\e9ac";
}
.icon-v2-folderfilled:before {
  content: "\e9ad";
}
.icon-v2-settingsfilled:before {
  content: "\e9ae";
}
.icon-v2-questionlined:before {
  content: "\e9af";
}
.icon-v2-questioncirclefillled:before {
  content: "\e9b0";
}
.icon-v2-bookmarkfilled:before {
  content: "\e9b1";
}
.icon-v2-bookmarklined:before {
  content: "\e9b2";
}
.icon-v2-starlined:before {
  content: "\e9b3";
}
.icon-v2-starfilled:before {
  content: "\e9b4";
}
.icon-v2-copyfilled:before {
  content: "\e9b5";
}
.icon-v2-playlined:before {
  content: "\e9b7";
}
.icon-v2-mapfilled:before {
  content: "\e9b8";
}
.icon-v2-maplined:before {
  content: "\e9b9";
}
.icon-v2-playfilled:before {
  content: "\e9ba";
}
.icon-v2-exclamationtrianglelined:before {
  content: "\e9bb";
}
.icon-v2-flaglined:before {
  content: "\e9bc";
}
.icon-v2-flagfilled:before {
  content: "\e9bd";
}
.icon-v2-bellfilled:before {
  content: "\e9be";
}
.icon-v2-belllined:before {
  content: "\e9bf";
}
.icon-v2-userfilled:before {
  content: "\e9c0";
}
.icon-v2-userlined1:before {
  content: "\e9c1";
}
.icon-v2-envelopelined:before {
  content: "\e9c2";
}
.icon-v2-chatlined:before {
  content: "\e9c3";
}
.icon-v2-chatfilled:before {
  content: "\e9c4";
}
.icon-v2-stepperfilled:before {
  content: "\e9c5";
}
.icon-v2-arrowdiagonalrightlined:before {
  content: "\e9c6";
}
.icon-v2-arrowdiagonalleftlined:before {
  content: "\e9c7";
}
.icon-v2-arrowuplined:before {
  content: "\e9c8";
}
.icon-v2-arrowdownlined:before {
  content: "\e9c9";
}
.icon-v2-arrowrightlined:before {
  content: "\e9ca";
}
.icon-v2-arrowleftlined:before {
  content: "\e9cb";
}
.icon-v2-chevronuplined:before {
  content: "\e9cc";
}
.icon-v2-chevrondownlined:before {
  content: "\e9cd";
}
.icon-v2-chevronrightlined:before {
  content: "\e9ce";
}
.icon-v2-chevronleftlined:before {
  content: "\e9cf";
}
.icon-v2-chevrondownfilled:before {
  content: "\e9d0";
}
.icon-v2-chevronupfilled:before {
  content: "\e9d1";
}
.icon-v2-chevronrightfilled:before {
  content: "\e9d2";
}
.icon-v2-chevronleftfilled:before {
  content: "\e9d3";
}
.icon-v2-arrowdoubledownlined:before {
  content: "\e9d4";
}
.icon-v2-arrowdoubleuplined:before {
  content: "\e9d5";
}
